
















































import { Auth } from "@/firebase";
import Component, { mixins } from "vue-class-component";
import { CurrentUserMixin, LoadingMixin } from "@/mixins/Helpers";
import AddIcon from "@/assets/icons/saxcons/add-broken.svg";
import UserProfileForm from "@/components/users/UserProfileForm.vue";
import LogoutIcon from "@/assets/icons/saxcons/logout-linear.svg";
import { signOut } from "@firebase/auth";
import { ResponsiveMixin } from "@/mixins/Responsive";

@Component({
  name: "profile",
  components: {
    AddIcon,
    UserProfileForm,
    LogoutIcon
  }
})
export default class Profile extends mixins(
  LoadingMixin,
  CurrentUserMixin,
  ResponsiveMixin
) {
  activeTab: string;
  constructor() {
    super();
    this.activeTab = this.$route.path;
  }
  async logOut() {
    await signOut(Auth);
  }
}
